<template>
  <div
    id="enter_into_await"
    ref="enter_into_await"
    class="enter_into_await my-video"
    @click="onClickVideoPlay"
  >
    <video
      v-show="false"
      id="videoPlayer"
      ref="videoPlayer"
      :src="src"
      autoplay
      muted
    />
    <img id="videoPoster" ref="videoPoster" :src="poster" />
    <canvas id="videoCanvas" ref="videoCanvas" />
  </div>
</template>

<script>
import "video.js/dist/video-js.css";
import videojs from "video.js";
let _this = {};
export default {
  props: {
    playerOptions: {},
    src: {
      type: String,
      default: "",
    },
    poster: {
      type: String,
      default: "",
    },
    show: Boolean,
  },
  data: function () {
    _this = this;

    return {
      // 视频播放
      videoPlayer: {},
      videoOvertimeGap: 2000,
      timeout: 500,
    };
  },
  methods: {
    setVideoPlayer() {
      const video = this.$refs.videoPlayer;
      const image = this.$refs.videoPoster;
      const canvas = this.$refs.videoCanvas;
      if (!video && !image && !canvas && 0 < this.timeout--) {
        this.timeout = 200;
        setTimeout(this.setVideoPlayer, 50);
        return;
      }

      if (!this.video) this.video = video;
      if (!this.image) this.image = image;
      if (!this.canvas) this.canvas = canvas;
      if (!this.context) this.context = canvas.getContext("2d");

      if (videojs) {
        if (!this.videoPlayer.id_)
          this.videoPlayer = videojs(video, {
            autoplay: false,
            controls: false,
            muted: true,
          });

        if (!this.videoPlayer.id_) setTimeout(this.setVideoPlayer, 50);
        this.videoPlayer.src({
          src: "./video/interlude-advertising/index.m3u8",
          type: "application/x-mpegURL",
        });
      } else this.videoPlayer = this.video;

      this.setVideoDom();
      const canplay = () => {
        _this.setVideoDom();
        _this.videoRenderCanvas();
        clearInterval(_this.$IntervalID["video_download"]);
      };
      video.addEventListener("canplay", canplay);
      video.addEventListener("canplaythrough", canplay);
      video.addEventListener("ended", _this.videoPlayerEnded);
      video.addEventListener("error", _this.videoPlayerEnded);

      this.videoPlayerPlay();
    },
    setVideoDom() {
      const aa = 60;
      const [dw, dh] = [document.body.clientWidth, document.body.clientHeight];
      const [w1, h1, w2, h2] = [1080, 1920, dw + aa, dh + aa];

      const [a1, a2] = [w1 / h1, w2 / h2];
      if (this.canvas.width === dw || this.canvas.height === dh) return;

      let style = {
        width: w2 + "px",
        height: h2 + "px",
      };

      if (a1 < a2) {
        style.height = (w2 / w1) * h1;
        style.width = w2;
      } else if (a2 < a1) {
        style.width = (h2 / h1) * w1;
        style.height = h2;
      }
      style.width += "px";
      style.height += "px";

      this.canvas.width = this.image.width;
      this.canvas.height = this.image.height;

      Object.assign(this.image.style, style);
      Object.assign(this.canvas.style, style);
    },
    // 视频画布制作
    videoRenderCanvas() {
      if (!this.video && !this.canvas && !this.image) return;
      if (this.$IntervalID["video_to_canvas"]) return;
      // 初始画布
      this.context.drawImage(
        this.image,
        0,
        0,
        this.canvas.width,
        this.canvas.height
      );
      const a = () => {
        this.context.drawImage(
          this.video,
          0,
          0,
          this.canvas.width,
          this.canvas.height
        );
      };
      a();
      this.$IntervalID["video_to_canvas"] = setInterval(a, 25);
    },
    // 视频播放
    videoPlayerPlay() {
      if (this.$IntervalID["video_to_canvas"] === undefined)
        //绘制视频
        this.context.drawImage(
          this.image,
          0,
          0,
          _this.canvas.width,
          _this.canvas.height
        );

      this.videoPlayer.src({
        src: "./video/interlude-advertising/index.m3u8",
        type: "application/x-mpegURL",
      });
      this.video.click();
      this.$refs.enter_into_await.click();
      this.videoPlayer.currentTime(0); // 设置视频播放位置
      this.videoPlayer.play(); // 播放
      this.videoPlayer_PreventExceedLimit();
    },
    // 视频结束
    videoPlayerEnded() {
      // 清除过渡界面超过视频时常延时
      clearTimeout(this.$IntervalID["setTimeout"]);
      // 清除视频下载超时计数
      clearInterval(this.$IntervalID["video_download"]);
      // 清除视频画布计数
      clearInterval(this.$IntervalID["video_to_canvas"]);
      // 删除各计数缓存
      delete this.$IntervalID["setTimeout"];
      delete this.$IntervalID["video_to_canvas"];
      // 设置过渡动画结束
      this.$emit("update:show", false);
    },
    // 防止视频加载超时
    videoPlayer_PreventExceedLimit() {
      clearTimeout(this.$IntervalID["setTimeout"]);
      clearInterval(this.$IntervalID["video_download"]);

      // 视频开始加载计数
      let overtime = new Date().valueOf();
      this.$IntervalID["video_download"] = setInterval(() => {
        // 判断视频开始加载时间到现在是否超时
        if (this.videoOvertimeGap < new Date().valueOf() - overtime)
          this.videoPlayerEnded(); // 超时结束过渡动画
      });

      // 防止视频过渡动画超过视频时常
      const a = this.videoPlayer.duration();
      if (a && a !== 0) {
        this.$IntervalID["setTimeout"] = setTimeout(() => {
          this.videoPlayerEnded();
        }, a * 1000 + 0.1);
      }
    },
    onClickVideoPlay(e) {
      e.stopPropagation();
      e.preventDefault();
      this.videoPlayer.play(); // 播放
      return false;
    },
  },
  created() {
    this.setVideoPlayer();
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState == "visible") {
        _this.$emit("update:show", true);
        _this.videoPlayerPlay();
      }
    });
  },
};
</script>

<style lang="less">
.enter_into_await {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 10%;
  height: 10%;
  background-color: rgba(0, 0, 0, 0);
  overflow: hidden;

  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // width: 0;
    // height: 0;
    min-width: 100%;
    min-height: 100%;
    max-width: unset;
    max-height: unset;
    padding: 0 0 0 0;
  }

  #videoPlayer {
    position: absolute;
    z-index: -9;

    .vjs-big-play-button {
      display: none;
    }
  }

  > canvas {
    min-width: 100%;
    min-height: 100%;
    max-width: unset;
    max-height: unset;
    background-color: rgba(128, 128, 128, 0);
  }
}
</style>
