
<template>
  <div id="User">
    <MyEnterIntoAwaitVideo
      v-show="enter_into_await.flag"
      :show.sync="enter_into_await.flag"
      src="./video/interlude-advertising/index.mp4"
      poster="./video/interlude-advertising/index.png"
    />
    <div class="user-body" v-show="!enter_into_await.flag">
      <template v-if="$route.name !== 'Forget-Password'">
        <div :class="`login-logo login-await`">
          <img :src="`${$public}/images/logo/logo-4.png`" alt="" />
        </div>
        <div class="login-await-transition">
          <div>
            <div class="login-title">
              <span>
                HI, 欢迎
                {{ $route.name === "Login" ? "登录" : " 注册" }}
                HM pool
              </span>
            </div>
            <div class="user-form">
              <router-view />
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <router-view />
      </template>
    </div>
  </div>
</template>

<script>
import "@/components/js/_form_common.js";
import MyEnterIntoAwaitVideo from "@/components/MyEnterIntoAwaitVideo";
export default {
  components: {
    MyEnterIntoAwaitVideo,
  },
  data: () => {
    return {
      enter_into_await: {
        flag: true,
      },
    };
  },
};
</script>

<style lang="less" >
#User {
  width: 100%;
  max-height: 100%;
  overflow: auto;
  padding: 2em;

  > .user-body {
    > [class*="logo"] {
      max-width: 576px;
      margin: 0 auto;
    }

    > div.login-await-transition {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      overflow: hidden;

      > div {
        > [class*="title"] {
          font-size: 1.5em;
          padding-top: 0.5em;
          margin-bottom: 0.5em;
        }

        > .user-form {
          button + button {
            margin-top: 0.5em;
          }
        }
      }
    }
  }
}
</style>
